import clsx from 'clsx';
import React from 'react';

export interface IProps {
    className?: string;
    isBgDark?: boolean;
    isCallToAction?: boolean;
    isSmall?: boolean;
    path: string;
    text: string;
    onClick?: () => void;
    onClickDisabled?: boolean;
}

export default function NavbarLink(props: IProps) {
    const className = clsx(
        props.className,
        'rounded-md text-base hover:no-underline hover:text-cyan-700',
        'hover:bg-gray-100 whitespace-nowrap',
        {
            'px-4 py-2': !props.isSmall,
            'px-2 py-1': props.isSmall,
            'text-gray-50 font-bold': props.isBgDark,
            'text-current font-medium': !props.isBgDark,
            'bg-white bg-opacity-10': props.isCallToAction && props.isBgDark,
            'border border-gray-600': props.isCallToAction && !props.isBgDark,
        }
    );

    if (!props.path) {
        return (
            <button onClick={props.onClick} className={className} disabled={props.onClickDisabled}>
                {props.text}
            </button>
        );
    }

    return (
        <a href={props.path} onClick={props.onClick} className={className}>
            {props.text}
        </a>
    );
}
