import React from 'react';
import BioLibLogoLightfrom from '../../images/biolib_logo_light.svg';
import NavbarLink from './NavbarLink';

const ConditionalLink: React.FC<{ to: string; children: React.ReactNode }> = (props) => {
    return <a href={props.to}>{props.children}</a>;
};

export default function Navbar() {
    return (
        <nav className="fixed top-0 z-50 w-full bg-gradient-dark">
            <div className="transition duration-500 ease-in-out bg-gradient-dark">
                <div className="container mx-auto px-4 lg:px-14 py-2 flex items-center justify-between">
                    <div className="flex items-center space-x-4 h-16">
                        <div className="flex-shrink-0">
                            <ConditionalLink to="/">
                                <img alt={'BioLib Logo'} className="h-8" draggable={false} src={BioLibLogoLightfrom} />
                            </ConditionalLink>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4 h-16">
                        <NavbarLink
                            text={'Sign in'}
                            path={'sign-in/'}
                            isBgDark={true}
                            isCallToAction={true}
                        />
                    </div>
                </div>
            </div>
        </nav>
    );
}
