import React from 'react';
import Layout from './components/layout';

interface IProps {
    element: React.ReactNode;
    props: any;
}

export function wrapPageElement(props: IProps) {
    return <Layout {...props.props}>{props.element}</Layout>;
}
