import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import '../styles/fonts.scss';

type LayoutProps = {
    children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps): JSX.Element {
    return (
        <>
            <Navbar />
            <div className="flex flex-col min-h-screen">{children}</div>
            <Footer />
        </>
    );
}
