import * as React from 'react';
import BioLibLogo from '../images/biolib_logo_dark.svg';
import { FaGithub, FaLinkedinIn, FaSlack } from 'react-icons/fa';

const socialLinks = [
    { Icon: FaGithub, url: 'https://github.com/biolib' },
    { Icon: FaLinkedinIn, url: 'https://linkedin.com/company/biolib' },
    { Icon: FaSlack, url: 'https://join.slack.com/t/biolibcommunity/shared_invite/zt-k9m6xqbp-Gz84qaKL3Of8P7FRWQ3KIQ' },
];

export default class Footer extends React.Component {
    public render() {
        return (
            <footer className="container mx-auto px-4 lg:px-14 mt-12 font-sans text-base mx-auto">
                <div className="flex flex-col md:flex-row">
                    <div className="flex-auto">
                        <img src={BioLibLogo} alt="BioLib Logo" className="h-8 mb-3" draggable={false} />
                        <p className="mb-3">Precision-engineered infrastructure for breakthrough discoveries</p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="flex flex-col md:flex-row items-center justify-between pb-6">
                    <div className="flex flex-wrap items-center mb-6 md:mb-0">
                        <span>© BioLib 2024</span>
                        <span className="mx-3">·</span>
                        <a href="legal/terms/" className="text-current hover:underline whitespace-nowrap">
                            Terms
                        </a>
                        <span className="mx-2 md:mx-3">·</span>
                        <a href="legal/privacy-policy/" className="text-current hover:underline whitespace-nowrap">
                            Privacy
                        </a>
                        <span className="mx-2 md:mx-3">·</span>
                        <a href="careers/" className="text-current hover:underline whitespace-nowrap">
                            Careers
                        </a>
                        <span className="mx-2 md:mx-3">·</span>
                        <a href="docs/" className="text-current hover:underline whitespace-nowrap">
                            Docs
                        </a>
                        <span className="mx-2 md:mx-3">·</span>
                        <a href="contact/" className="text-current hover:underline whitespace-nowrap">
                            Contact
                        </a>
                    </div>
                    <div className="flex-initial flex flex-row md:float-right space-x-3">
                        {socialLinks.map(({ Icon, url }, index) => (
                            <a
                                key={index}
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="rounded h-7 w-7 flex items-center justify-center
                                    bg-gray-800 hover:bg-cyan-700 text-white hover:text-white text-lg"
                            >
                                <Icon />
                            </a>
                        ))}
                    </div>
                </div>
            </footer>
        );
    }
}
