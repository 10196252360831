import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface IInquiry {
    email: string;
    message: string;
    name: string;
}

const isProduction = process.env.NODE_ENV === 'production';

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: isProduction ? 'https://biolib.com/api' : 'http://localhost:8000/api',
    }),
    endpoints: (build) => ({
        sendInquiry: build.mutation<{}, IInquiry>({
            query: (args) => ({
                url: '/system/inquiries/',
                method: 'POST',
                body: args,
            }),
        }),
    }),
});

export const { useSendInquiryMutation } = api;
